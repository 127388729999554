import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit, AfterViewInit {

  @ViewChild('backgroundCanvasElement', {static: false}) backgroundCanvasElement: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  mouseX: number = 0;
  mouseY: number = 0;

  constructor() {
  }

  ngOnInit(): void {
    window.onresize = () => this.onResize();
    window.onmousemove = (event) => this.mouseMove(event);
  }

  dots: Array<Dot> = [];
  ngAfterViewInit(): void {
    this.context = this.backgroundCanvasElement.nativeElement.getContext('2d');

    const computedStyles = getComputedStyle(this.backgroundCanvasElement.nativeElement);
    this.backgroundCanvasElement.nativeElement.width = parseInt(computedStyles.width);
    this.backgroundCanvasElement.nativeElement.height = parseInt(computedStyles.height);

    let width = this.backgroundCanvasElement.nativeElement.width;
    let height = this.backgroundCanvasElement.nativeElement.height;

    for (let i = 0; i < 1000; i++) {
      let x = (Math.random() * (width * 2)) - width / 2; // Give a random y position
      let y = (Math.random() * (height * 2)) - height / 2; // Give a random y position
      let radius = (Math.random() * 3) + 1; // Give a random y position
      let z = (Math.random() * (radius - 1)); // Give a random z position

      this.dots.push(new Dot(x, y, z, radius))
    }

    // this.dots.push(new Dot(x, y+100, 2, 5))
    // this.dots.push(new Dot(x, y+200, 1, 5))

    this.onResize();

  }

// // Create 800 new dots
//
// }

  onResize() {

    const computedStyles = getComputedStyle(this.backgroundCanvasElement.nativeElement);
    this.backgroundCanvasElement.nativeElement.width = parseInt(computedStyles.width);
    this.backgroundCanvasElement.nativeElement.height = parseInt(computedStyles.height);

    this.draw();
  }

  point(x, y, canvas) {
    canvas.beginPath();
    canvas.arc(x, y, 5, 0, 2 * Math.PI, true);
    canvas.stroke();
  }

  mouseMove(event) {
    this.mouseX = event.clientX - (this.backgroundCanvasElement.nativeElement.width / 2);
    this.mouseY = event.clientY - (this.backgroundCanvasElement.nativeElement.height / 2);
    this.draw();
  }

  draw() {
    this.context.clearRect(0, 0, this.backgroundCanvasElement.nativeElement.width, this.backgroundCanvasElement.nativeElement.height);
    this.dots.forEach(dot => {
      dot.draw(this.context, this.mouseX, this.mouseY)
    })
  }

}


export class Dot {
  x;
  y;
  z;
  radius;

  static MOVEMENT_FACTOR = 0.05;

  constructor(x, y, z, radius) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.radius = radius;
  }

  draw(ctx, mouseX, mouseY) {

    let calcX = ((mouseX * ((this.radius - this.z)/this.radius)) * Dot.MOVEMENT_FACTOR) + this.x
    let calcY = ((mouseY * ((this.radius - this.z)/this.radius)) * Dot.MOVEMENT_FACTOR) + this.y

    ctx.globalAlpha = Math.abs(((this.radius - this.z)/this.radius));

    ctx.fillStyle = "#FFF";
    ctx.beginPath();
    ctx.arc(calcX, calcY, this.radius - this.z, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
  }
}
